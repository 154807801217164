import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AuthGuard } from '@guards/auth.guard';
import { VerifiedEmailGuard } from '@guards/verifiedEmail.guard';
import { RoleGuard } from '@guards/role.guard';

const isShopSubdomain: boolean =
  window.location.hostname.split('.').length > 2 ||
  (window.location.hostname.includes('localhost') && window.location.hostname.split('.').length === 2);

const SHOPS_routes: Routes = [
  { path: '',
    loadChildren: () => import('./features/shop-app/shop-app.routes').then(m => m.SHOP_ROUTES) },
  { path: '**', component: PageNotFoundComponent },
];

const ADMIN_routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./features/home/pages/landing/landing.component')
      .then(m => m.LandingComponent) },

  {
    path: 'business-sign-up',
    loadComponent: () => import('./features/business-registration/pages/business-sign-up/business-sign-up.component').then(m => m.BusinessSignUpComponent) },
  {
    path: 'email-verification',
    loadComponent: () => import('./features/business-registration/pages/verify-email/verify-email.component').then(m => m.VerifyEmailComponent) },
  {
    path: 'new-shop',
    loadComponent: () => import('./features/shop-app-creation/dialogs/new-shop/new-shop.component').then(m => m.NewShopComponent),
    canMatch: [AuthGuard, VerifiedEmailGuard, RoleGuard],
    data: { roles: ['admin'] },},
  {
    path: 'my-shops',
    loadComponent: () => import('./features/business-hub/components/my-shops/my-shops.component').then(m => m.MyShopsComponent),
    canMatch: [AuthGuard, VerifiedEmailGuard, RoleGuard],
    data: { roles: ['admin'] },},
  /**
  {
    path: 'admin',
    canMatch: [AuthGuard, VerifiedEmailGuard, RoleGuard],
    data: { roles: ['admin'] },
    children: [
      { path: '', loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule) },
    ],
  },
   **/
  { path: '**', component: PageNotFoundComponent },
];

export const routes: Routes = isShopSubdomain ? SHOPS_routes : ADMIN_routes;

import {ApplicationConfig, importProvidersFrom, inject} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import {provideFirebaseApp, initializeApp, FirebaseApp} from '@angular/fire/app';
import {provideAuth, getAuth, connectAuthEmulator, Auth} from '@angular/fire/auth';
import {
  provideFirestore,
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  Firestore
} from '@angular/fire/firestore';
import {provideFunctions, getFunctions, connectFunctionsEmulator, Functions} from '@angular/fire/functions';
import {provideStorage, getStorage, connectStorageEmulator} from '@angular/fire/storage';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Services
import { AuthService } from '@services/auth.service';
import { NetworkService } from '@services/network.service';
import { FsService } from "@services/fs.service";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {ServiceWorkerService} from "@services/service-worker.service";
import {of} from "rxjs";
import {ScreenService} from "@services/screen.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    TranslateModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth: Auth = getAuth(inject(FirebaseApp));
      if (environment.useEmulators) {
        connectAuthEmulator(auth, `http://localhost:${environment.emulatorPorts.auth}`, { disableWarnings: true });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore: Firestore = initializeFirestore(inject(FirebaseApp),{
        localCache: persistentLocalCache(),
      });

      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, '127.0.0.1', environment.emulatorPorts.firestore);
      }

      return firestore;
    }),
    provideFunctions(() => {
      const functions: Functions = getFunctions(inject(FirebaseApp), 'europe-west3');

      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, '127.0.0.1', environment.emulatorPorts.functions);
      }

      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage(inject(FirebaseApp));
      if (environment.useEmulators) {
        connectStorageEmulator(storage, '127.0.0.1', environment.emulatorPorts.storage);
      }
      return storage;
    }),
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    importProvidersFrom(
      [
        ServiceWorkerModule.register('service-worker.js', {
        enabled: true,
        registrationStrategy: 'registerImmediately' // 🔥 Charge immédiatement le SW
    })],
      [
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useValue: {
              getTranslation: () => of({})
            }
          },
        })
      ]),
    ServiceWorkerService,
    {
      provide: 'app-init-screen-service',
      useFactory: () => inject(ScreenService)
    },
    {
      provide: 'app-init-network-service',
      useFactory: () => inject(NetworkService),
    },
    //provideAnalytics(() => getAnalytics()),
  ],
};


<mat-drawer-container hasBackdrop="true" style="background-color: rgba(0, 0, 0, 0)" class="view-port">
  <mat-drawer #drawer mode="over">
    <app-menu (toggleSidenav)="drawer.toggle()"></app-menu>
  </mat-drawer>
  <mat-drawer-content>
    <app-header (toggleSidenav)="drawer.toggle()"></app-header>
    <main class="main-view-port" headerScrollToggle [viewPort]="viewPort()" [mainViewPort]="mainViewPort()">
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>

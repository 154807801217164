import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {FsService} from "@services/fs.service";
import {AuthService} from "@services/auth.service";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class ShopService {
  constructor(private router: Router,
              private fsService: FsService,
              private auth: AuthService) {
    this.getData();
  }


  /** this service listen for shopId$  */
  public shopId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private role$: BehaviorSubject<"platform" | "anonymous" | "pre-registration" | "reception" | "admin" | null | undefined> = this.auth.role$
  public shopId: string | null = null;


  public syncShopIdWithUrl(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const newShopId: string | null = this.extractShopIdFromUrl();

        if (newShopId !== this.shopId) {
          this.shopId = newShopId;
          this.shopId$.next(newShopId);
        }
      });
  }

  private extractShopIdFromUrl(): string | null {
    const isShopSubdomain: boolean = window.location.hostname.split('.').length > 2 || window.location.hostname.includes('localhost') && window.location.hostname.split('.').length == 2;
    if(isShopSubdomain){
      const parts: string[] = window.location.hostname.split('.');
      return parts[0];
    }

    if(!isShopSubdomain){
      const pathSegments: string[] = window.location.pathname.split('/').filter(segment => segment.length > 0);
      if (pathSegments.length >= 2 && pathSegments[0] === 'admin') {
        return pathSegments[1]; // Le second segment est le shopId
      }
    }
    return null;
  }


  private getData(): void{
    this.shopId$.subscribe(async (shopId) => {
      await this.fsService.getShop(shopId);
      this.role$.subscribe(async (role) => {
        try {
          switch (role) {

            /** reception and platform need to subscribe to fleet and today rents **/
            case 'reception':
              await this.fsService.getFleet(shopId);
              await this.fsService.getRents(shopId);
              break;

            case 'platform':
              await this.fsService.getFleet(shopId);
              await this.fsService.getRents(shopId);
              break;

            /** business-hub need only fleet, and one certain component only, maybe calling it in those component is better ? **/
            case 'admin':
              await this.fsService.getShop(shopId);
              await this.fsService.getFleet(shopId);
              break;

            /** other role do not need those => dismount and stop listening **/
            default:
              await this.fsService.getFleet(null);
              await this.fsService.getRents(null);
              break;
          }
        } catch (e: any) {
          console.error("error in shopService => subscribeToRole$");
          console.error(e);
        }
      })
    });
  }
}

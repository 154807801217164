import {
  Directive,
  effect,
  inject, input, InputSignal,
  Renderer2,
  signal,
  WritableSignal
} from "@angular/core";
import {ScreenService} from "@services/screen.service";

@Directive({
  selector: '[headerScrollToggle]',
  standalone: true,
})
export class HeaderScrollToggleDirective {
  public readonly viewPort: InputSignal<HTMLElement | null> = input<HTMLElement | null>(null);
  public readonly mainViewPort: InputSignal<HTMLElement | null> = input<HTMLElement | null>(null);
  readonly height: WritableSignal<number> = inject(ScreenService).height
  private readonly renderer = inject(Renderer2);

  private viewPortEl: HTMLElement | null =  null;
  private mainViewPortEl: HTMLElement | null = null;

  private isCompact: WritableSignal<boolean> = signal<boolean>(false);
  private scrollOn: WritableSignal<'view-port' | 'main-view-port'> = signal<'view-port' | 'main-view-port'>('view-port');
  private lastScrollTop: number = 0;


  constructor(){
    effect(() => {
      const height: number = this.height();
      this.isCompact.set(height < 600);
    });

    effect(() => {
      this.viewPortEl = this.viewPort()
      this.mainViewPortEl = this.mainViewPort()

      if(!this.viewPortEl || !this.mainViewPortEl){
        return;
      }

      this.viewPortEl.addEventListener('scroll', () => {
        //if(!this.isCompact()) return;
        const isScrolledOut: boolean= this.isHeaderScrolledOut(this.viewPortEl!);
        console.log(isScrolledOut);

        if(isScrolledOut){ // keep scrolling view-port until it change
          console.log('header scrolled out');
          this.scrollOn.set('view-port');
        } else {
          this.scrollOn.set('main-view-port');
        }
      })

      this.mainViewPortEl.addEventListener('scroll', () => {


        const currentScrollTop: number = this.mainViewPortEl!.scrollTop;
        let scrollDirection: 'up' | 'down' =  currentScrollTop < this.lastScrollTop ? 'up' : 'down';
        this.lastScrollTop = currentScrollTop

        if(scrollDirection == 'up'){
          this.scrollOn.set('view-port');
        }
      })
    });

    effect(() => {
      const compact: boolean = this.isCompact();
      if(!compact) { // return to non-compact mode => scroll ony on main-view-port => header always displayed
        if(this.scrollOn() != 'view-port') this.scrollOn.set('view-port');
        return;
      }
    });

    effect(() => {
      const target = this.scrollOn();
      console.log('jump scroll to: ', target);

      if (!this.viewPortEl || !this.mainViewPortEl) return;

      const add = (el: HTMLElement, className: string) =>
        this.renderer.addClass(el, className);
      const remove = (el: HTMLElement, className: string) =>
        this.renderer.removeClass(el, className);

      if (target === 'view-port') {
        add(this.viewPortEl, 'scrollable');
        remove(this.viewPortEl, 'no-scroll');
        add(this.mainViewPortEl, 'no-scroll');
        remove(this.mainViewPortEl, 'scrollable');
      }

      if (target === 'main-view-port') {
        add(this.mainViewPortEl, 'scrollable');
        remove(this.mainViewPortEl, 'no-scroll');
        add(this.viewPortEl, 'no-scroll');
        remove(this.viewPortEl, 'scrollable');
      }
    });
  }

  private isHeaderScrolledOut(el: HTMLElement): boolean {
    const scrollTop = el.scrollTop;
    const maxScroll = el.scrollHeight - el.clientHeight;
    console.log('header scrolled out ?: ', scrollTop >= maxScroll)
    return scrollTop >= maxScroll;
  }
}

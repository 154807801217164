<h2 mat-dialog-title *ngIf="!(isAuth$ | async) && !isShopSubdomain">Accéder à votre espace</h2>
<h2 mat-dialog-title *ngIf="(role$ |async) == 'admin' && !isShopSubdomain">ADMINISTRATEUR</h2>
<h2 mat-dialog-title *ngIf="isShopSubdomain">Mode d'utilisation</h2>

<mat-dialog-content>
  <form  [formGroup]='loginForm' [cdkTrapFocus]="!isShopSubdomain">
    <mat-form-field *ngIf="isShopSubdomain" appearance="outline">
      <mat-label>Station</mat-label>
      <mat-select placeholder="CHOIX STATION" formControlName="role">
        <mat-option *ngFor="let station of stations" [value]="station.role" >{{station.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!isShopSubdomain && !(isAuth$ | async)">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" type="email" autocomplete="email">
      <mat-error *ngIf="loginForm.get('email')?.hasError('required')">Email requis</mat-error>
      <mat-error *ngIf="loginForm.get('email')?.hasError('email')">Email invalide</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="loginForm.get('role')?.value != (role$ | async) || !(isAuth$ | async)">
      <mat-label>Mot de passe</mat-label>
      <input matInput formControlName="password" [type]="hide? 'password' : 'text'" autocomplete="password">
      <mat-icon matSuffix *ngIf="hide" (click)="togglePasswordVisibility()">visibility</mat-icon>
      <mat-icon matSuffix *ngIf="!hide" (click)="togglePasswordVisibility()">disabled_visible</mat-icon>
      <mat-error *ngIf="loginForm.get('password')?.hasError('required')">Mot de passe requis</mat-error>
      <mat-error *ngIf="loginForm.get('password')?.hasError('invalid-credential')">Erreur: email et/ou mot de passe</mat-error>
      <mat-error *ngIf="loginForm.get('password')?.hasError('too-many-requests')">Accés refusé: Trop de tentative</mat-error>
      <mat-error *ngIf="loginForm.get('password')?.hasError('unknown-error')">Erreur inconnue</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button class='primary' (click)="submit()" *ngIf="!(isAuth$ | async) || loginForm.get('role')?.value != (role$ | async)">CONNEXION</button>
  <button mat-flat-button class="warn" *ngIf="(isAuth$ | async) && loginForm.get('role')?.value == (role$ | async) && loginForm.get('role')?.value != 'pre-registration'" (click)="logout()">DECONNEXION</button>
</mat-dialog-actions>

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { FsService } from '@services/fs.service';
import { Shop } from '@models/shop.model';
import { Title } from "@angular/platform-browser";
import { StorageService } from "@services/storage.service";
import { SwUpdate } from '@angular/service-worker';
import {ShopService} from "@services/shop.service";

@Injectable({
  providedIn: 'root'
})
export class HeadManagerService {
  private renderer: Renderer2;
  private currentShopId: string | null = null;
  private manifestUpdateLock: Promise<void> = Promise.resolve();

  constructor(rendererFactory: RendererFactory2, private fsService: FsService, private title: Title, private storageService: StorageService, private swUpdate: SwUpdate, private shopService: ShopService) {
    this.renderer = rendererFactory.createRenderer(null, null)
    /** removed multiple service worker registration **/
    this.serviceWorkerRegistration();

    // Subscribe to the current shop to load the appropriate head tags
    this.fsService.shop$.subscribe(async (shop: Shop | null) => {
      console.log('shop$ trigger');
      let hasSubdomain: boolean = this.shopService.getSubdomain() != null
      if(hasSubdomain){
        if(shop){
          await this.handleShopUpdate(shop);
        }
      } else {
        await this.handleShopUpdate(null);
      }

    });

    // Check for service worker updates
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY' && confirm('Une nouvelle version est disponible, mettre à jour ?')) {
          window.location.reload();
        }
      });
    }
  }

  private async serviceWorkerRegistration() {
    const swPath = `/custom-sw.js`;

    if ('serviceWorker' in navigator) {
      // Unregister all existing service workers
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }

      // Register the new service worker
      navigator.serviceWorker.register(swPath).then(function(registration) {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch(function(error) {
        console.log('Service Worker registration failed:', error);
      });
    }
  }

  private async handleShopUpdate(shop: Shop | null) {
    // Attendre la fin de toute mise à jour en cours avant de commencer une nouvelle mise à jour.
    await this.manifestUpdateLock;

    // Définir une nouvelle mise à jour comme étant en cours
    this.manifestUpdateLock = (async () => {
      let manifest: any;
      let manifestUrl: string = `/local/manifest.json`;
      if (shop) {
        this.currentShopId = shop.id;
        this.title.setTitle(shop.getName);
        manifest = await this.generateManifest(shop);
      } else {
        this.currentShopId = null;
        this.title.setTitle('Rent Wave');
        manifest = await this.loadDefaultManifest();
      }

      this.generateFaviconTags(manifest);
      //this.generateAppleTags(manifest);
      this.updateCachedManisfest(manifest, manifestUrl);
      this.injectManifestLink(manifestUrl);
    })();
    /** remove and update theme meta tag **/
    const metaThemeColorTag = document.querySelector('meta[name="theme-color"]');
    if ( metaThemeColorTag) {
      this.renderer.removeChild( metaThemeColorTag.parentNode,  metaThemeColorTag);
    }

    const metaThemeColor = this.renderer.createElement('meta');
    metaThemeColor.name = "theme-color";
    metaThemeColor.content = this.getBackgroundColorOfMatToolbar();
    this.renderer.appendChild(document.head, metaThemeColor);

    const metaAppleMobileWebAppStatusBarStyle = this.renderer.createElement('meta');
    metaAppleMobileWebAppStatusBarStyle.name = "apple-mobile-web-app-status-bar-style"
    metaAppleMobileWebAppStatusBarStyle.content = "black-translucent"
    this.renderer.appendChild(document.head, metaAppleMobileWebAppStatusBarStyle);
  }

  private async generateManifest(shop: Shop) {
    const baseUrl = window.location.origin; // Get the base URL
    console.log("generate custom manifest");
    return {
      id: `${baseUrl}`,
      lang: 'fr-FR',
      name: shop.name,
      short_name: shop.name,
      description: 'Rental optimizer for ' + shop.name, // Description of the app.
      start_url: `${baseUrl}`,
      scope: `${baseUrl}/`,
      display: "fullscreen",
      display_override: ["fullscreen", "standalone", "minimal-ui", "browser"],
      background_color: '#006a6a',
      theme_color: '#006a6a',

      orientation: 'landscape', // Default orientation ('portrait', 'landscape', 'any'),
      dir: 'ltr',
      prefer_related_applications: false,
      related_applications: [],
      categories: ["business", "productivity"],
      icons: await this.generateIcons(shop.settings.iconsBucket),
    };
  }

  public async loadDefaultManifest() {
    const baseUrl = window.location.origin; // Get the base URL
    return {
      id: `${baseUrl}`,
      lang: "fr-FR",
      name: "Rent Wave",
      short_name: "Rent Wave",
      description: "Rental optimizer for all kind shop",
      start_url: `${baseUrl}`, // Use absolute URL
      scope: `${baseUrl}/`,     // Use absolute URL
      display: "fullscreen",
      display_override: ["fullscreen", "standalone", "minimal-ui", "browser"],
      background_color: "#3f51b5",
      theme_color: "#3f51b5",
      orientation: "landscape",
      dir: "ltr",
      prefer_related_applications: false,
      related_applications: [],
      categories: ["business", "productivity"],
      icons: await this.generateIcons('rentwave')
    };
  }

  private updateCachedManisfest(manifest: any, manisfestUrl: string){
    // Mettre à jour le manifest dans le cache du service worker
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'UPDATE_MANIFEST',
        manifest: JSON.stringify(manifest),
        manifestUrl: manisfestUrl
      });
    }
  }

  private injectManifestLink(manifestUrl: string) {
    const existingManifest = document.querySelector('link[rel="manifest"]');
    if (existingManifest) {
      this.renderer.removeChild(document.head, existingManifest);
    }
    const manifestLink = this.renderer.createElement('link');
    manifestLink.rel = 'manifest';
    manifestLink.href = manifestUrl; // URL du manifest mis à jour
    this.renderer.appendChild(document.head, manifestLink);
  }

  private async generateIcons(icons: string | null): Promise<{ src: string, type: 'image/png', sizes: string }[]> {
    if (!icons) {
      return [];
    }

    const iconList = await this.storageService.listAllImagesInBucket('icons/' + icons);

    return iconList.map(icon => {
      const sizeMatch = icon.name.match(/icon(\d+)/);
      const size = sizeMatch ? sizeMatch[1] : 'unknown';

      return {
        src: icon.url,
        type: 'image/png',
        sizes: `${size}x${size}`
      };
    });
  }

  private generateFaviconTags(manifest: any) {
    // Supprime les favicon tags existants
    const existingFaviconTags = document.querySelectorAll('link[rel="icon"]');
    existingFaviconTags.forEach(tag => {
      this.renderer.removeChild(document.head, tag);
    });

    // Crée et ajoute les nouveaux favicon tags
    const sizes = ['16x16', '32x32', '192x192'];
    sizes.forEach(size => {
      const icon = manifest.icons.find((i: any) => i.sizes === size);
      if (icon) {
        const linkElement = this.renderer.createElement('link');
        linkElement.rel = 'icon';
        linkElement.type = 'image/png';
        linkElement.sizes = size;
        linkElement.href = icon.src;
        this.renderer.appendChild(document.head, linkElement);
      }
    });
  }

  getBackgroundColorOfMatToolbar(): string | null {
    const toolbarElement = document.querySelector('mat-toolbar');
    if (toolbarElement) {
      const computedStyle = window.getComputedStyle(toolbarElement);
      console.log(computedStyle.backgroundColor)
      return computedStyle.backgroundColor;
    }
    return null;
  }

  /*
  private removeExistingiOSMetaTags() {
    const existingMetaTags = document.querySelectorAll('meta[name^="apple-mobile-web-app"], link[rel="apple-touch-icon"]');
    existingMetaTags.forEach(tag => {
      this.renderer.removeChild(document.head, tag);
    });
  }

  private generateAppleTags(manifest: any) {
    const baseUrl = window.location.origin; // Get the base URL
    //this.removeExistingiOSMetaTags();

    // Add iOS specific meta tags
    const appleMobileWebAppCapable = this.renderer.createElement('meta');
    appleMobileWebAppCapable.name = 'mobile-web-app-capable';
    appleMobileWebAppCapable.content = 'yes';
    this.renderer.appendChild(document.head, appleMobileWebAppCapable);

    const appleMobileWebAppTitle = this.renderer.createElement('meta');
    appleMobileWebAppTitle.name = 'apple-mobile-web-app-title';
    appleMobileWebAppTitle.content = manifest.name;
    this.renderer.appendChild(document.head, appleMobileWebAppTitle);

    const appleMobileWebAppStatusBarStyle = this.renderer.createElement('meta');
    appleMobileWebAppStatusBarStyle.name = 'apple-mobile-web-app-status-bar-style';
    appleMobileWebAppStatusBarStyle.content = 'black-translucent';
    this.renderer.appendChild(document.head, appleMobileWebAppStatusBarStyle);

    // Add multiple apple-touch-icon tags for different sizes
    const appleTouchIcons = [
      { sizes: '120x120', src: 'icon120' },
      { sizes: '152x152', src: 'icon152' },
      { sizes: '167x167', src: 'icon167' },
      { sizes: '180x180', src: 'icon180' }
    ];

    appleTouchIcons.forEach(icon => {
      const iconSrc = manifest.icons.find((i: any) => i.sizes === icon.sizes)?.src;
      if (iconSrc) {
        const iconElement = this.renderer.createElement('link');
        iconElement.rel = 'apple-touch-icon';
        iconElement.sizes = icon.sizes;
        iconElement.href = `${baseUrl}/${iconSrc}`;
        this.renderer.appendChild(document.head, iconElement);
      }
    });
  }
  */
}



import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ScheduleService } from '@services/schedule.service';
import { FsService } from '@services/fs.service';
import { AuthService } from '@services/auth.service';
import { NetworkService } from '@services/network.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogLoginComponent } from '../../dialogs/dialog-login/dialog-login.component';
import { DialogOfflineComponent } from '../../dialogs/dialog-offline/dialog-offline.component';
import { Shop } from '@models/shop.model';
import { LanguagesService } from '@services/languages.service';
import { Observable, map } from 'rxjs';
import {CommonModule} from "@angular/common";
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton, MatMiniFabButton} from "@angular/material/button";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbar,
    MatIcon,
    MatIconButton,
    MatMiniFabButton
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();
  public shopName: string = '';
  public now$: Observable<Date>;
  public role: "anonymous" | "pre-registration" | "reception" | "platform" | "admin" | null | undefined;
  public flag$: Observable<string | null>;
  public online: boolean = this.networkService.online$.value;
  private dialogRef: any;
  private screenWidth: number;

  constructor(
    private scheduleService: ScheduleService,
    private fs: FsService,
    private authService: AuthService,
    private networkService: NetworkService,
    private languageService: LanguagesService,
    public dialog: MatDialog
  ) {
    this.screenWidth = window.innerWidth;
    this.role = this.authService.role$.value;
    this.flag$ = this.languageService.selectedFlag$;
    this.now$ = this.scheduleService.time$.asObservable().pipe(map(time => time.now));
  }

  public openMenu(): void {
    this.toggleSidenav.emit(true);
  }

  public openLoginDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '300px';
    dialogConfig.width = '300px';
    dialogConfig.maxWidth = '600px';
    dialogConfig.minHeight = '200px';
    dialogConfig.maxHeight = '80vh';
    dialogConfig.autoFocus = false;
    this.dialog.open(DialogLoginComponent, dialogConfig);
  }

  public changeLang(): void {
    this.languageService.dismountSelected();
    localStorage.removeItem('language');
  }

  ngOnInit(): void {
    this.scheduleService.time$.subscribe((time) => {
      this.now$ = new Observable<Date>((observer) => observer.next(time.now));
    });

    this.authService.role$.subscribe((role: "anonymous" | "pre-registration" | "reception" | "platform" | "admin" | null | undefined): void => {
      this.role = role;
    });

    this.networkService.online$.subscribe((online: boolean) => {
      if (this.online && !online) { // passe de online à offline
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        if (this.role != 'reception' && this.role != 'admin') {
          this.dialogRef = this.dialog.open(DialogOfflineComponent, dialogConfig);
        }
      }

      if (!this.online && online) { // passe de offline à online
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }
      this.online = online;
    });

    this.fs.shop$.subscribe((shop: Shop | null): void => {
      if(shop){
        this.shopName = shop.getName;
      } else {
        this.shopName = 'RENT WAVE'
      }
    });
  }
}

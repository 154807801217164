import { Component } from '@angular/core';
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { AuthService } from "@services/auth.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CdkTrapFocus} from "@angular/cdk/a11y";


@Component({
  selector: 'app-dialog-login',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgIf,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    CdkTrapFocus
  ],
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.scss']
})
export class DialogLoginComponent {
  public role$ = this.authService.role$;
  public isAuth$ = this.authService.isAuth$;
  public isShopSubdomain: boolean = window.location.hostname.split('.').length > 2 || window.location.hostname.includes('localhost') && window.location.hostname.split('.').length == 2;
  public shopId: string | null = this.isShopSubdomain ? window.location.hostname.split('.')[0] : null;
  public stations = [
    { label: 'ACCEUIL', role: 'reception'},
    { label: 'EQUIPEMENT', role: 'platform'},
    { label: 'PUBLIC', role: 'pre-registration'}
  ];
  public hide: boolean = true;

  constructor(public dialogRef: MatDialogRef<DialogLoginComponent>,
              public authService: AuthService) {
    this.loginForm.get('role')?.valueChanges.subscribe((role) => {
      if(role == 'reception' ||role == 'platform' || role == 'pre-registration')
      this.loginForm.patchValue({email: role+'@'+this.shopId+'.rentwave.app'})
    })
  }

  public loginForm: FormGroup = new FormGroup<{
    role?: FormControl<string|null>,
    email: FormControl<string|null>,
    password: FormControl<string|null>,
  }>({
    role: new FormControl(this.role$.value? this.role$.value as string : null),
    email: new FormControl<string | null>(null, {
      validators: [Validators.required, Validators.email],
      updateOn: 'change'
    }),
    password: new FormControl<string | null>(null, [Validators.required])
  });


  // Method to close the dialog
  public cancel(): void {
    this.dialogRef.close();
  }

  // toggle password visibility
  public togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }

  // Method to submit the login form
  public async submit(): Promise<void> {
    try {
      await this.authService.signInWithEmail(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value);
      this.dialogRef.close();
    } catch (error: any){
      console.log(error.code);
      switch (error.code){
        case 'auth/invalid-credential':
          this.loginForm.get('password')?.setErrors({"invalid-credential": true});
          break;
        case 'auth/too-many-requests':
          this.loginForm.get('password')?.setErrors({"too-many-requests": true});
          break;
        default:
          this.loginForm.get('password')?.setErrors({"unknown-error": true});
          break;
      }
    }
  }

  // Method to sign out the user
  public logout(): void {
    this.authService.signOut();
    this.dialogRef.close();
  }
}

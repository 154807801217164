import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {Manifest} from "@models/manifest.models";
import {ShopService} from "@services/shop.service";
import {MatSidenavModule} from "@angular/material/sidenav";
import {CommonModule} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import { MenuComponent } from './shared/components/menu/menu.component';
import { HeaderComponent } from './shared/components/header/header.component';
import {HeaderScrollToggleDirective} from "@directives/header-scroll-toggle.directive";

class writableSignal<T> {
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatSidenavModule,
    MenuComponent,
    HeaderComponent,
    HeaderScrollToggleDirective,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit{
  public viewPort : WritableSignal<HTMLElement | null> = signal(null);
  public mainViewPort:  WritableSignal<HTMLElement | null> = signal(null);

  ngAfterViewInit(): void {
    this.viewPort.set(document.querySelector('.view-port') as HTMLElement | null);
    this.mainViewPort.set(document.querySelector('.main-view-port') as HTMLElement | null);
  }



  constructor(private renderer: Renderer2, private shopService: ShopService) {}
  public title: string = '';

  private isPwa(): void {
    const isPwa: boolean = window.matchMedia('(display-mode: standalone)').matches;
    console.log('application installé: '+isPwa);
  }

  private async setBackgroundImage(): Promise<void> {
    const host = window.location.hostname;
    const parts = host.split('.');
    let shopId: string | null = null;
    const isLocalhost = host.includes('localhost');
    if (parts.length > 2 && !isLocalhost) {
      shopId = parts[0];
    }
    if (isLocalhost && parts.length === 2) {
      shopId = parts[0];
    }

    const mainBackgroundUrl = shopId
      ? `https://firebasestorage.googleapis.com/v0/b/rentwave-prod.appspot.com/o/shops%2F${shopId}%2Fbackground.webp?alt=media`
      : null;

    const fallbackBackgroundUrl =
      "https://firebasestorage.googleapis.com/v0/b/rentwave-prod.appspot.com/o/backgrounds%2Fdefault-background-transparency.webp?alt=media";

    const backgroundImageValue = mainBackgroundUrl
      ? `url('${mainBackgroundUrl}'), url('${fallbackBackgroundUrl}')`
      : `url('${fallbackBackgroundUrl}')`;

    this.renderer.setStyle(document.body, 'background-image', backgroundImageValue);
  }

  private async setFaviconAndName(): Promise<void> {
    await this.waitForManifest();
    const manifestLink: HTMLLinkElement = document.querySelector("link[rel='manifest']") as HTMLLinkElement;
    const manifestUrl: string = manifestLink.href
    const response: Response = await fetch(manifestUrl);
    const manifest: Manifest =  new Manifest();
    Object.assign(manifest, await response.json())

    const titleElement = this.renderer.selectRootElement('title', true);
    this.renderer.setProperty(titleElement, 'textContent', manifest.name);

    manifest.icons.forEach((icon) => {
      const link = this.renderer.createElement("link");
      this.renderer.setAttribute(link, "rel", "icon");
      this.renderer.setAttribute(link, "type", "image/png");
      this.renderer.setAttribute(link, "sizes", icon.sizes);
      this.renderer.setAttribute(link, "href", icon.src);
      this.renderer.appendChild(document.head, link);
    })
  }

  private waitForManifest(): Promise<void> {
    return new Promise(resolve => {
      const checkManifest = () => {
        const manifestLink = document.querySelector("link[rel='manifest']");
        if (manifestLink) {
          resolve();
        } else {
          setTimeout(checkManifest, 50);
        }
      };
      checkManifest();
    });
  }

  ngOnInit(): void {
    this.isPwa();
    this.setBackgroundImage().then();
    this.setFaviconAndName().then();
    this.shopService.syncShopIdWithUrl();

    /**
    window.onerror = function(message, source, lineno, colno, error) {
      alert(`Error: ${message} at ${source}:${lineno}:${colno}`);
    };
     **/
  }
}

export class Manifest {
  constructor(
    public  id: string = 'rentwave',
    public lang: string = 'fr-FR',
    public name: string = 'Rentwave',
    public short_name: string = 'RW',
    public description: string = 'Rental optimizer for fast rental business',
    public start_url: string = `https://rentwave.app`,
    public scope: string = 'https://rentwave.app/',
    public display: string = 'fullscreen',
    public display_override: string[] = ['fullscreen', 'standalone', 'minimal-ui', 'browser'],
    public background_color: string = '#006a6a',
    public theme_color: string = '#006a6a',
    public orientation: string = 'landscape',
    public dir: string = 'ltr',
    public  prefer_related_applications: boolean = false,
    public  related_applications: any[] = [],
    public categories: string[] = ['business', 'productivity'],
    public icons: {src: string, sizes: string , type: string}[] = []
  ) {}

  public toFirestore(this: Manifest): any {
    return {
      id: this.id,
      name: this.name,
      short_name: this.short_name,
      description: this.description,
      start_url: this.start_url,
      scope: this.scope,
      icons: this.icons
    }
  }
}

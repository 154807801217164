import { Component, OnInit } from '@angular/core';
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-dialog-offline',
  standalone: true,
  imports: [
    MatDialogContent,
    MatIcon,
    MatProgressSpinner
  ],
  templateUrl: './dialog-offline.component.html',
  styleUrls: ['./dialog-offline.component.scss'],
})
export class DialogOfflineComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogOfflineComponent>) { }

  ngOnInit(): void {
  }

}

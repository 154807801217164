import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {Shop} from "@models/shop.model";
import {FsService} from "@services/fs.service";
import {CommonModule} from "@angular/common";
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {MatList, MatListItem, MatListSubheaderCssMatStyler} from "@angular/material/list";
import {RouterLink} from "@angular/router";
import {MatIconButton} from "@angular/material/button";

interface MenuItems {
  header: string,
  roles: string[],
  list: {
    name: string,
    link: string,
    icon: string,
    description: string | undefined,
    roles: string[],
    displayOn: ('smallScreen'| 'largeScreen')[]
  }[]
}

@Component({
  selector: 'app-menu',
  standalone: true,
  imports:[
    CommonModule,
    MatToolbar,
    MatIcon,
    MatIconButton,
    MatListSubheaderCssMatStyler,
    MatList,
    MatListItem,
    RouterLink
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})

export class MenuComponent implements OnInit{
  public menuItems: MenuItems[] = [];
  public shopName: string = '';
  public screenSize: 'smallScreen'| 'largeScreen' = 'largeScreen';
  public role: "anonymous" | "pre-registration" | "reception" | "platform" | "admin" | null | undefined;
  private screenWidth: number;
  private minSize: number = 400;

  constructor(private authService: AuthService,
              private fs: FsService) {
    this.screenWidth = window.innerWidth;
    this.role = this.authService.role$.value;
    this.menuItems = [
        {
          header: 'Espace Opérationnel',
          roles: ['reception', 'platform'],
          list: [
            {
              name: 'Reservation',
              link: '/registration',
              icon: 'point_of_sale',
              description: 'Gestion de la caisse',
              roles: ['reception'],
              displayOn: ['smallScreen','largeScreen']
            },
            {
              name: 'Planning',
              link: '/planning',
              icon: 'event_note',
              description: 'Visualisation du planning du jour',
              roles: ['reception'],
              displayOn: ['smallScreen', "largeScreen"]
            },
            {
              name: 'Pre-enregistrés',
              link: '/pre-registered',
              icon: 'more_time',
              description: 'Gestion des pré-enregistrement',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Zone d\'accueil',
              link: '/reception-zone',
              icon: 'fact_check',
              description: 'Gestion des clients en attentes et des clotures',
              roles: ['reception', 'platform'],
              displayOn: ['largeScreen']
            },
            {
              name: 'En attente',
              link: '/waiting',
              icon: 'hourglass_top',
              description: 'Gestion des clients en attente',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Appel en cours',
              link: '/called',
              icon: 'notifications_active',
              description: 'Gestion des clients invité à se présenter',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Zone d\'équipement',
              link: '/rental-zone',
              icon: 'fact_check',
              description: 'Gestion des départs et des retours',
              roles: ['reception', 'platform'],
              displayOn: ['largeScreen']
            },
            {
              name: 'Location en équipement',
              link: '/boarding',
              icon: 'radio_button_unchecked',
              description: 'Gestion des departs',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Location en cours',
              link: '/in-use',
              icon: 'play_circle_outline',
              description: 'Gestion des retours',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Location terminé',
              link: '/landing',
              icon: 'task_alt',
              description: 'Gestion des clotures',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
          ]
        },
        {
          header: 'Paramètres',
          roles: ['reception'],
          list: [
            {
              name: 'Paramètres fiche de location',
              link: '/shop-settings',
              icon: 'settings',
              description: 'Paramétrage des fiches de location',
              roles: ['reception', 'admin'],
              displayOn: ['smallScreen', 'largeScreen']
            },

            {
              name: 'Gestionnaire de flotte',
              link: '/fleet-settings',
              icon: 'format_list_numbered',
              description: 'Paramétrage de la flotte',
              roles: ['reception'],
              displayOn: ['smallScreen', 'largeScreen']
            },

            {
              name: 'Tarifs',
              link: '/rate-settings',
              icon: 'price_check',
              description: 'Paramétrage de la tarification',
              roles: ['reception'],
              displayOn: ['smallScreen', "largeScreen"]
            },
          ]
        },
        {
          header: 'Statistiques',
          roles: ['admin'],
          list: [
            {
              name: 'Statistiques',
              link: '/business-hub/statistiques',
              icon: 'bar_chart',
              description: 'Statistiques avancées',
              roles: ['admin'],
              displayOn: ['smallScreen', 'largeScreen']
            }
          ]
        }
      ]
  }

  @Output() toggleSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();
  @HostListener('window:resize', ['$event'])
  sizeChange(event: any): void {
    this.screenWidth = event.currentTarget.innerWidth;
    this.isSmall();
  }

  private isSmall(): void {
   this.screenSize = this.screenWidth < this.minSize? 'smallScreen': 'largeScreen';
  }

  public close(): void {
    this.toggleSidenav.emit();
  }


  async ngOnInit(): Promise<void> {
    this.screenWidth = window.innerWidth;
    this.isSmall();

    this.authService.role$.subscribe((role) => {
      this.role = role;
    });

    this.fs.shop$.subscribe((shop: Shop | null): void => {
      if(shop){
        this.shopName = shop.getName;
      }
    });
  }
}

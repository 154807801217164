// verifiedEmail.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanMatch,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Route, UrlSegment
} from '@angular/router';
import {AuthService} from "@services/auth.service";
import {User} from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class VerifiedEmailGuard implements CanActivate, CanMatch {
  // let pass if email is verified else redirect to /business-sign-up/verify-email
  constructor(private authService: AuthService, private router: Router) {}

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    return this.checkAccess(route);
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.checkAccess(route);
  }

  private async checkAccess(route: Route | ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    await this.authService.waitForInit();
    const user: User | null = this.authService.user$.value;
    if(user){
      if(user.emailVerified){
        return true;
      } else {
        console.warn('VerifiedEmailGuard: Unverified Email');
        await this.router.navigate(['/business-sign-up/verify-email']);
        return false
      }
    } else {
      // Should not append if auth.guards is correctly implanted on the route.
      await this.router.navigate(['/'])
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import {SwUpdate} from "@angular/service-worker";

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  constructor(private swUpdate: SwUpdate) {
    this.listenForUpdates();
  }

  private listenForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(() => {
        console.log('🚀 Nouvelle version détectée ! Mise à jour immédiate...');
        this.forceUpdate();
      });
    }
  }

  private forceUpdate(): void {
    navigator.serviceWorker.getRegistration().then(reg => {
      if (reg && reg.waiting) {
        console.log('🔄 Activation du nouveau SW...');
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    });
  }
}

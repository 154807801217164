import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * **EXPORT**
 * online$ = new BehaviorSubject<boolean>(navigator.onLine);
 **/
export class NetworkService {
  public online$ = new BehaviorSubject<boolean>(navigator.onLine);

  constructor() {
    this.checkNetworkStatus();
  }

  private checkNetworkStatus() {
    window.addEventListener('offline', () => {
      this.online$.next(false);
    });
    window.addEventListener('online', () => {
      this.online$.next(true);
    });
  }
}
